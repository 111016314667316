import { DSInterface } from '@dreamshaper/ds-interface'

export const useDSInterface = createGlobalState(() => {
  const { auth, org, user } = useUser()

  const config = useRuntimeConfig()

  const dsEndpoint = config.public.apiUrl

  const dsInterface = new DSInterface({
    dsEndpoint,
    jwt: auth.value?.jwt || undefined,
    jwtRecover: auth.value?.recover || undefined,
    organizationId: org.value?.id,
    userId: user.value?.id,
  })

  return dsInterface
})
